<template>
  <div class="text-left">
    <div>
      <div class>
        <h4>Edp {{ phase.phase }}</h4>
        <base-form-row row>
          <base-input
            v-model="phase.phase"
            :errors="feedback.phase"
            inputText="Phase"
            darkBackgroundMode
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <BaseSlideNumber
            v-model.number="phase.ded"
            text="DeD (mSv/h)"
            :max="30"
            :min="0"
            :errors="feedback.ded"
            :step="0.001"
            darkBackgroundMode
          ></BaseSlideNumber>
        </base-form-row>
        <base-form-row row>
          <BaseSlideNumber
            v-model.number="phase.temps"
            text="Temps (heures)"
            :max="1000"
            :min="0"
            :errors="feedback.temps"
            :step="0.01"
            darkBackgroundMode
          ></BaseSlideNumber>
        </base-form-row>
        <base-form-row row>
          <BaseSlideNumber
            v-model.number="phase.coef"
            text="Coef"
            :max="15"
            :min="0"
            :errors="feedback.coef"
            :step="0.1"
            darkBackgroundMode
          ></BaseSlideNumber>
        </base-form-row>
        <base-form-row row>
          <BaseSlideNumber
            v-model.number="phase.iteration"
            text="Itération"
            :max="50"
            :min="0"
            :errors="feedback.iteration"
            darkBackgroundMode
          ></BaseSlideNumber>
        </base-form-row>
        <base-form-row row>
          <BaseSlideNumber
            v-model.number="phase.nbre_ope"
            text="Nombre d'opérateur(s)"
            :max="50"
            :min="0"
            :errors="feedback.nbre_ope"
            darkBackgroundMode
          ></BaseSlideNumber>
        </base-form-row>
        <div class="d-flex justify-content-between mt-3">
          <BaseButton
            class="btn btn-danger"
            @click="deleteEdpPhase(phase)"
            :loading="loading"
            text="Supprimer"
            icon="trash"
          ></BaseButton>
          <BaseButton
            class="btn btn-primary"
            @click="updateEdpPhase(phase)"
            :loading="loading"
            text="Enregistrer"
            icon="save"
          ></BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
//import ButtonCircle from "@/components/bases/ButtonCircle.vue";
//import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSlideNumber from "@/components/bases/SlideNumber.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: { BaseFormRow, BaseInput, BaseSlideNumber, BaseButton },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: ""
    };
  },
  props: {
    phase: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      _updateEdpPhase: "dosimetrie/updateEdpPhase",
      _deleteEdpPhase: "dosimetrie/deleteEdpPhase"
    }),
    updateEdpPhase: function(Edp) {
      this.feedback = {};
      this.loading = true;
      this._updateEdpPhase(Edp)
        .then(response => (this.statut = response.data))
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteEdpPhase: function(Edp) {
      this.feedback = {};
      this.loading = true;
      this._deleteEdpPhase(Edp)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css">
td {
  vertical-align: middle !important;
}
</style>