<template>
  <tr v-if="phase">
    <td>
      <small>{{phase.phase}}</small>
    </td>
    <td class="text-center">
      <small>{{ phase.ded.toFixed(3) }}</small>
    </td>
    <td class="text-center">
      <small>{{ phase.temps }}</small>
    </td>
    <td class="text-center">
      <small>{{ phase.iteration }}</small>
    </td>
    <td class="text-center">
      <small>{{ phase.coef.toFixed(1) }}</small>
    </td>
    <td class="text-center">
      <small>{{ phase.nbre_ope }}</small>
    </td>
    <td style="line-height:1.1rem;">
      <div>
        <small>
          <span class="text-muted">Indiv.</span>
          <span class="ml-1">{{dose_indiv.toFixed(3)}} mSv</span>
        </small>
      </div>
      <div>
        <small>
          <span class="text-muted">Collec.</span>
          <span class="ml-1">{{dose_collective.toFixed(3)}} H.mSv</span>
        </small>
      </div>
      <div>
        <small>
          <span class="text-muted">VTE</span>
          <span class="ml-1">{{vte.toFixed(2)}} H.h</span>
        </small>
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        <v-popover placement="left">
          <ButtonCircle class="m-auto" size="18" name="edit" :padding="2" propagation></ButtonCircle>
          <template slot="popover">
            <DosimetrieEdpPhaseForm :phase="phase"></DosimetrieEdpPhaseForm>
          </template>
        </v-popover>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import DosimetrieEdpPhaseForm from "@/components/affaires/DosimetrieEdpPhaseForm";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { DosimetrieEdpPhaseForm, ButtonCircle },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: "",
      showForm: false
    };
  },
  props: {
    phase: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {
    ...mapGetters({ data: "affaires/data", user: "user/data" }),
    dose_indiv() {
      return (
        parseFloat(this.phase.ded) *
        parseFloat(this.phase.temps) *
        parseFloat(this.phase.coef) *
        parseFloat(this.phase.iteration)
      );
    },
    dose_collective() {
      return (
        parseFloat(this.phase.ded) *
        parseFloat(this.phase.temps) *
        parseFloat(this.phase.coef) *
        parseFloat(this.phase.nbre_ope) *
        parseFloat(this.phase.iteration)
      );
    },
    vte() {
      return parseFloat(this.phase.temps) * parseFloat(this.phase.nbre_ope);
    }
  },
  methods: {
    ...mapActions({
      _deleteEdpPhase: "dosimetrie/deleteEdpPhase"
    }),
    deleteEdpPhase: function(phase) {
      this.feedback = {};
      this.loading = true;
      this._deleteEdpPhase(phase)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css">
td {
  vertical-align: middle !important;
}
</style>