<template>
  <div class="d-flex flex-column flex-grow-1 rounded pr-3">
    <BaseToolBar
      title="Evaluation(s) dosimétrique prévisionnelle(s)"
      subtitle="Liste des EDP enregistrées sur cette affaire"
    >
      <BaseButton
        class="btn btn-primary"
        @click="createEdp(id)"
        :loading="loadingEdp"
        text="Ajouter une EDP"
        icon="plus"
      ></BaseButton>
    </BaseToolBar>
    <div class="rounded shadow-sm p-3 mb-3" v-background-3>
      <uploader
        v-if="id"
        id="document_affaire_edp"
        url="/upload/document"
        title="Vos fichiers"
        :file_system="'CRM'"
        :file_field="'edp'"
        :file_key="id"
      ></uploader>
    </div>
    <div class="d-flex flex-column flex-md-row w-100 pr-1">
      <div class="flex-grow-1 d-flex flex-column flex-md-row flex-wrap mb-3" v-if="edps">
        <div v-for="edp in edps" :key="edp.id" class="w-100">
          <dosimetrie-edp :edp="edp" :doses="doses" :lists="lists"></dosimetrie-edp>
        </div>
      </div>
    </div>

    <div>
      <div v-for="collab in collabs" :key="'collab_' + collab.id" class="w-100">
        <div class="d-flex">
          <div class="mr-1">{{ collab.collaborateur_nom }} {{ collab.collaborateur_prenom }}</div>
          <div>{{ collab.dose.toFixed(3) }} mSv</div>
        </div>
      </div>
    </div>

    <!--<BaseToolBar title="Doses d'affaire" subtitle="Liste des doses enregistrées sur cette affaire">
      <BaseButton
        class="btn btn-primary"
        @click="createDose(id)"
        :loading="loadingDose"
        text="Ajouter une dose"
        icon="plus"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100 pr-1">
      <div class="flex-grow-1 d-flex flex-column flex-md-row flex-wrap mb-3" v-if="doses">
        <div v-for="dose in doses" :key="dose.id" class="w-100">
          <dose-item :dose="dose" :lists="lists" :collaborateurs="collaborateurs"></dose-item>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import BaseToolBar from "@/components/bases/ToolBar.vue";
//import DoseItem from "@/components/affaires/DosimetrieItem.vue";
import DosimetrieEdp from "@/components/affaires/DosimetrieEdp.vue";
import BaseButton from "@/components/bases/Button.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: {
    BaseToolBar,
    //DoseItem,
    BaseButton,
    DosimetrieEdp,
    Uploader,
  },
  data() {
    return {
      loading: false,
      loadingDose: false,
      loadingEdp: false,
      lists: {},
      feedback: {},
      selectedEdpPhaseId: 0,
      collaborateurs: [],
    };
  },
  props: {},
  computed: {
    ...mapFields("affaires", ["affaire.id"]),
    ...mapMultiRowFields("dosimetrie", ["doses"]),
    ...mapMultiRowFields("dosimetrie", ["edps"]),
    ...mapMultiRowFields("dosimetrie", ["edpPhases"]),
    totalCollectifPrev() {
      let cpt = 0;
      this.edps.forEach((edp) => {
        cpt = cpt + edp.ded * edp.temps * edp.coef * edp.nbre_ope;
      });
      return cpt;
    },
    totalCollectif() {
      let cpt = 0;
      this.doses.forEach((dose) => {
        cpt = cpt + dose.dosi_dose;
      });
      return cpt;
    },

    collabs() {
      const d = this.doses;
      let collaborateurs = [];

      if (d.length > 0) {
        d.forEach((dose) => {
          if (!collaborateurs.some((collab) => collab.id === dose.dosi_collaborateur_id)) {
            collaborateurs.push({
              id: dose.dosi_collaborateur_id,
              collaborateur_nom: dose.collaborateur.collaborateur_nom,
              collaborateur_prenom: dose.collaborateur.collaborateur_prenom,
              dose: dose.dosi_dose,
            });
          } else {
            const i = collaborateurs.findIndex((collab) => collab.id === dose.dosi_collaborateur_id);
            collaborateurs[i].dose = collaborateurs[i].dose + dose.dosi_dose;
          }
        });

        return collaborateurs;
      }

      return null;
    },
  },
  methods: {
    ...mapActions({
      _createDose: "dosimetrie/create",
      _createEdp: "dosimetrie/createEdp",
      _updateEdp: "dosimetrie/updateEdp",
      _deleteEdp: "dosimetrie/deleteEdp",
    }),
    createDose: function () {
      this.feedback = {};
      this.loadingDose = true;
      this._createDose({
        dosi_affaire_id: this.id,
        dosi_collab_id: null,
      })
        .catch((error) => errHandler(error))
        .finally(() => (this.loadingDose = false));
    },
    createEdp: function () {
      this.feedback = {};
      this.loadingEdp = true;
      this._createEdp({
        affaire_id: this.id,
      })
        .catch((error) => errHandler(error))
        .finally(() => (this.loadingEdp = false));
    },
    updateEdp: function (Edp) {
      this.feedback = {};
      this.loading = true;
      this._updateEdp(Edp)
        //.then(response => (this.statut = response.data))
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteEdp: function (Edp) {
      this.feedback = {};
      this.loadingEdp = true;
      this._deleteEdp(Edp)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingEdp = false));
    },
    getCollaborateursTeamData() {
      this.$store
        .dispatch("collaborateurs/getCollaborateursAll")
        .then((response) => {
          this.collaborateurs = response;
        })
        .finally(() => (this.loading = false));

      if (this.value) {
        this.selectedCollabId = this.value;
      }
    },
    errHandler: function (error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    showForm: function (data) {
      this.selectedEdpPhaseId = data.id;
    },
  },
  mounted() {
    this.getCollaborateursTeamData();
  },
};
</script>
<style lang="css">
.qwark-modal-form {
  position: absolute;
  display: block;
  background: white;
  padding: 20px;
  z-index: 2000;
  top: 20px;
  left: 0;
  right: 0;
  max-width: 700px;
  max-height: 100%;
  overflow: auto;
  margin: auto;
}
</style>
