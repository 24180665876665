<template>
  <div class="d-flex flex-column w-100 shadow-sm rounded mb-3" v-background-3>
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="p-3 m-3">
          <h6 class="m-0">{{ edp.description }}</h6>
        </div>
        <div class="d-flex m-3 align-items-center">
          <div class="border rounded p-2 mr-3">
            <div>{{ totalCollectifPrev.toFixed(3) }} H.mSv</div>
            <div class="text-muted">
              <small>collectif</small>
            </div>
          </div>
          <!--<div class="border rounded p-2 mr-3">
            <div>{{ totalIndividuel.toFixed(3) }} mSv</div>
            <div class="text-muted">
              <small>individuel</small>
            </div>
          </div>-->
          <div class="border rounded p-2 mr-3">
            <div>{{ totalRealise.toFixed(3) }} H.mSv</div>
            <div class="text-muted">
              <small>Réalisé</small>
            </div>
          </div>
          <div style="height:65px;" v-if="totalRealise > 0">
            <apexchart width="100" type="radialBar" :options="options" :series="series" class></apexchart>
          </div>
          <div class="d-flex align-items-center">
            <button-circle
              v-if="expand == true"
              name="trash-2"
              size="18"
              @click="deleteEdp(edp)"
              :loading="loading"
            ></button-circle>
            <BaseButton
              v-if="expand == true"
              class="btn btn-primary ml-3 mr-3"
              @click="updateEdp(edp)"
              :loading="loading"
              text="Enregistrer"
              icon="save"
            ></BaseButton>
            <button-circle
              v-if="expand == true"
              name="arrow-up"
              size="18"
              @click="expand = false"
              :loading="loading"
            ></button-circle>
            <button-circle
              v-if="expand == false"
              name="arrow-down"
              size="18"
              @click="expand = true"
              :loading="loading"
            ></button-circle>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3 w-100" v-if="expand">
      <div class="p-3 rounded">
        <base-form-row row>
          <base-input v-model="edp.description" :errors="feedback.description" inputText="Titre"></base-input>
          <base-input v-model="edp.site" :errors="feedback.site" inputText="Site"></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input v-model="edp.tranche" :errors="feedback.tranche" inputText="Tranche"></base-input>
          <base-input
            v-model="edp.nbr_intervenant_total"
            :errors="feedback.nbr_intervenant_total"
            inputText="Nombre total d'intervenant"
          ></base-input>
        </base-form-row>
        <base-form-row row class="mb-4">
          <base-input
            v-model="edp.commentaire"
            :errors="feedback.commentaire"
            inputText="Commentaire"
          ></base-input>
        </base-form-row>
      </div>
    </div>

    <table class="table table-borderless table-responsive-xl" v-if="expand">
      <thead>
        <tr class="border-bottom">
          <th scope="col">
            <small>
              <strong>Activité</strong>
            </small>
          </th>
          <th scope="col" class="text-center">
            <small>
              <strong>DeD (mSv/h)</strong>
            </small>
          </th>
          <th scope="col" class="text-center">
            <small>
              <strong>Temps (h)</strong>
            </small>
          </th>
          <th scope="col" class="text-center">
            <small>
              <strong>Intération</strong>
            </small>
          </th>
          <th scope="col" class="text-center">
            <small>
              <strong>Coef</strong>
            </small>
          </th>
          <th scope="col" class="text-center">
            <small>
              <strong>Nb. Opé</strong>
            </small>
          </th>
          <th scope="col">
            <small>
              <strong>Résultats</strong>
            </small>
          </th>
          <th scope="col" class>
            <ButtonCircle
              class="m-auto"
              size="20"
              name="plus"
              colorClass="btn-primary"
              :padding="2"
              :loading="loadingEdp"
              v-tooltip="'Ajouter une ligne'"
              @click="createEdpPhase"
            ></ButtonCircle>
          </th>
        </tr>
      </thead>
      <tbody v-for="edpPhase in edpPhases" :key="edpPhase.id">
        <dosimetrie-edp-phase :phase="edpPhase" v-if="edpPhase.edp_id === edp.id"></dosimetrie-edp-phase>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import DosimetrieEdpPhase from "@/components/affaires/DosimetrieEdpPhase.vue";
import BaseInput from "@/components/bases/Input.vue";
//import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    ButtonCircle,
    DosimetrieEdpPhase,
    BaseInput,
    //BaseForm,
    BaseFormRow,
    BaseButton
  },
  data() {
    return {
      feedback: {},
      loading: false,
      loadingEdp: false,
      statut: "",
      tab: true,
      expand: false
    };
  },
  props: {
    edp: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    doses: {
      Type: Array,
      Requierd: true,
      Default: []
    }
  },
  computed: {
    ...mapGetters({ data: "affaires/data", user: "user/data" }),
    ...mapMultiRowFields("dosimetrie", ["edpPhases"]),
    totalCollectifPrev() {
      let cpt = 0;
      this.edpPhases.forEach(phase => {
        if (phase.edp_id === this.edp.id)
          cpt =
            cpt +
            phase.ded *
              phase.temps *
              phase.coef *
              phase.nbre_ope *
              phase.iteration;
      });
      return cpt;
    },
    totalIndividuel() {
      let cpt = 0;
      this.edpPhases.forEach(phase => {
        if (phase.edp_id === this.edp.id)
          cpt = cpt + phase.ded * phase.temps * phase.coef * phase.iteration;
      });
      return cpt;
    },
    totalRealise() {
      let cpt = 0;
      this.doses.forEach(dose => {
        if (dose.dosi_edp_id == this.edp.id) cpt = cpt + dose.dosi_dose;
      });
      return cpt;
    },
    series() {
      return [((this.totalRealise / this.totalCollectifPrev) * 100).toFixed(0)];
    },
    options() {
      return {
        plotOptions: {
          radialBar: {
            offsetY: -8,
            dataLabels: {
              value: {
                offsetY: -11,
                fontSize: "12px",
                color: undefined,
                formatter: function(val) {
                  return val;
                }
              }
            }
          }
        },
        labels: [""]
      };
    }
  },
  methods: {
    ...mapActions({
      _createEdpPhase: "dosimetrie/createEdpPhase",
      _updateEdp: "dosimetrie/updateEdp",
      _deleteEdp: "dosimetrie/deleteEdp"
    }),
    createEdpPhase: function() {
      this.feedback = {};
      this.loadingEdp = true;
      this._createEdpPhase({
        edp_id: this.edp.id
      })
        .catch(error => errHandler(error))
        .finally(() => (this.loadingEdp = false));
    },
    updateEdp: function(Edp) {
      this.feedback = {};
      this.loading = true;
      this._updateEdp(Edp)
        .then(response => (this.statut = response.data))
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteEdp: function(Edp) {
      this.feedback = {};
      this.loading = true;
      this._deleteEdp(Edp)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css">
td {
  vertical-align: middle !important;
}
</style>
